import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'; // Import der Umgebungsvariablen
import { BehaviorSubject, Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
	public isAuth = new BehaviorSubject<boolean>(false);

	private baseUrl = environment.apiUrl; // Verwendung der API-URL aus den Umgebungsvariablen

	constructor(private http: HttpClient, public jwtHelper: JwtHelperService, private router: Router) {

	}

	checkTokenValidity(): void {
		const token = localStorage.getItem('token');
		if (token) {
			const decodedToken: any = jwtDecode(token);
			
			// Hole das Datum der letzten Rechteänderung aus dem Token
			const tokenLastRightsUpdate = decodedToken.lastRightsUpdate;
		
			// Anfrage an den Server, um den aktuellen `lastRightsUpdate`-Wert zu bekommen
			this.http.get<{ lastRightsUpdate: string }>(`${this.baseUrl}/auth/getLastRightsUpdate`).subscribe((data) => {
				if (
					(new Date(data.lastRightsUpdate) > new Date(tokenLastRightsUpdate)) || 
					(tokenLastRightsUpdate === undefined || tokenLastRightsUpdate === null || tokenLastRightsUpdate === '')
				) {				
					// Wenn die Rechte aktualisiert wurden, setze eine Nachricht in den localStorage und logge den Benutzer aus
					localStorage.setItem('logoutReason', 'Ihre Rechte wurden geändert. Bitte melden Sie sich erneut an.');
					this.logout(); // Sofortiger Logout und Redirect
				}
			});
		}
	}	

	getUserFullname(): string {
		const token = localStorage.getItem('token');
		if (token) {
		  const decodedToken: any = jwtDecode(token);
	
		  // Angenommen, das Token enthält die Felder "forename" und "surname"
		  const forename = decodedToken.forename || '';
		  const surname = decodedToken.surname || '';
	
		  // Vollständiger Name wird zusammengesetzt
		  const fullname = `${forename} ${surname}`.trim();
		  return fullname;
		}
		return '';  // Falls kein Token vorhanden ist, leerer String
	  }

	  getUserEmail(): string {
		const token = localStorage.getItem('token');
		if (token) {
		  const decodedToken: any = jwtDecode(token);
	
		  const email = decodedToken.email;
	
		  return email;
		}
		return '';  // Falls kein Token vorhanden ist, leerer String
	  }

	register(userData: { username: string; email: string; password: string }): Observable<any> {
		return this.http.post(`${this.baseUrl}/auth/register`, userData);
	}

	login(username: string, password: string, authorityKey: string): Observable<any> {
		return this.http.post(`${this.baseUrl}/auth/login`, { username, password, authorityKey }).pipe(
			tap((response: any) => {
				if (response && response.token) {
					localStorage.setItem('token', response.token); // Token im localStorage speichern
				}
			})
		);
	}
	  
	isUserTokenExpired(): boolean{
		if (localStorage.getItem('token') === null || localStorage.getItem('token') === undefined || localStorage.getItem('token') === '') {
			return true; //Muss an dieser Stelle true returnen, da die Funktion 'isTokenExpired()' prüft, ob das Token abgelaufen ist.
		} else {
			if(this.jwtHelper.isTokenExpired(localStorage.getItem('token'))){
				return true;
			} else {
				return false;
			}
		}
	}

	logout(): void {
		localStorage.removeItem('token');
		this.router.navigate(['/login']);
	}
	

	checkUsernameAvailability(username: string, authorityId: string): Observable<any> {
		return this.http.get<any>(`${this.baseUrl}/auth/isUsernameFree`, {
		  	params: { username, authorityId }
		});
	}
}
